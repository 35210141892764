import React from "react";
import pumpkin from "../assets/Images/pumpkin.jpg";
import marbel from "../assets/Images/marbel.jpg";
import figure from "../assets/Images/figure.jpg";
import javi_logo2 from "../assets/Images/javi_logo2.png";

const Nav = () => {
  return (
    <div>
      <header class="header p-3 position-absolute start-0 top-0 end-0">
        <div class="d-flex justify-content-between align-items-center">
          <a href="/" class="text-decoration-none text-dark fs-5 fw-bold">
            <img style={{ width: "10rem" }} src={javi_logo2} />
          </a>

          {/* <div>
            <button
              class="navbar-toggler text-white"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar"
              aria-controls="navbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                fill="currentColor"
                class="bi bi-list"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                />
              </svg>
            </button>
          </div> */}
        </div>
      </header>
      {/* <nav className="collapse navbar-collapse dropdown-nav" id="navbar">
        <div className="dropdown-nav__container container-xxl d-flex align-items-start align-items-md-center">
          <div className="row align-items-start">
          <div className="col-12 col-sm-4 mt-5">
              <a href="#" className="row text-decoration-none">
                <div className="col-5 col-sm-12 mb-4">
                  <img
                    src={pumpkin}
                    alt="Coffe Flavour"
                    className="img-fluid"
                    style={{width: "80%", borderRadius: "20px"}}
                    loading="lazy"
                  />
                </div>
                <div className="col-7">
                  <h3>Pumpkin</h3>
                  
                
                    <button type="button" class="btn btn-outline-dark">Checkout</button>
                  
                </div>
              </a>
            </div>
            <div className="col-12 col-sm-4 mt-5">
              <a href="#" className="row text-decoration-none">
                <div className="col-5 col-sm-12 mb-4">
                  <img
                    src={marbel}
                    alt="Coffe Flavour"
                    className="img-fluid"
                    style={{width: "80%", borderRadius: "20px"}}
                    loading="lazy"
                  />
                </div>
                <div className="col-7">
                  <h3>Marbel</h3>
                  
                
                    <button type="button" class="btn btn-outline-dark">Checkout</button>
                  
                </div>
              </a>
            </div>
            <div className="col-12 col-sm-4">
              <a href="#" className="mt-5 row text-decoration-none">
                <div className="col-5 col-sm-12 mb-4">
                  <img
                    src={figure}
                    alt="Coffe Flavour"
                    className="img-fluid"
                    style={{width: "80%", borderRadius: "20px"}}
                    loading="lazy"
                  />
                </div>
                <div className="col-7">
                  <h4>figurine</h4>
                  <div>
                    <button type="button" class="btn btn-outline-dark">Checkout</button>
                  </div>
                   
                </div>
              </a>
            </div>
          </div>
          <button
            className="navbar-toggler dropdown-nav__closeNavBtn"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar"
            aria-controls="navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={36}
              height={36}
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </div>
      </nav> */}
    </div>
  );
};

export default Nav;
