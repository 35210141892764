import React from "react";

const Footer = () => {
  return (
    <div className="mt-0">
      <footer className="footer bg-dark text-white">
        <div className="container-custom d-flex justify-content-between align-items-center py-3 border-highlight">
          <div className="col-md-4 d-flex align-items-center">
            <a href="/" className="me-2 text-muted text-decoration-none">
              <i className="bi bi-cup" />
              <span className="text-white">© 2022 Javi Glass</span>
            </a>
          </div>
          {/* <ul className="nav col-md-4 justify-content-end list-unstyled d-flex text-white">
            <li className="ms-3 ">
              <a className="text-muted" href="#">
                <i class="fab fa-twitter"></i>
              </a>
            </li>
            <li className="ms-3">
              <a className="text-muted" href="#">
                <i class="fab fa-instagram"></i>
              </a>
            </li>
            <li className="ms-3">
              <a className="text-muted" href="#">
                <i class="fab fa-facebook-f"></i>
              </a>
            </li>
          </ul> */}
        </div>
      </footer>
    </div>
  );
};

export default Footer;
