import React, { useEffect, useState } from "react";
import { urlFor, client } from "../client";
import javi_logo from "../assets/Images/javi_logo.png";

const Marbels = () => {
  const [marbels, setMarbels] = useState([]);

  useEffect(() => {
    client.fetch('*[_type == "marbels"]').then((data) => {
      setMarbels(data);
    });
  }, []);

  return (
    <div
      style={{ background: "white" }}
      className="d-flex justify-content-center row"
    >
      <header class="header p-3 position-absolute start-0 top-0 end-0">
        <div class="d-flex justify-content-between align-items-center">
          <a href="/" class="text-decoration-none text-dark fs-5 fw-bold">
            <img style={{ width: "10rem" }} src={javi_logo} />
          </a>
        </div>
      </header>
      <h1
        style={{ marginTop: "100px" }}
        className="hero__heading fw-bold mb-4  text-center"
      >
        Our Marbles
      </h1>
      {marbels.map((marbel, index) => (
        <div class="card m-3" style={{ width: "18rem" }}>
          <img
            class="card-img-top mt-2"
            src={urlFor(marbel.imgUrl)}
            alt="Card image cap"
          />
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">
              Marbles can be made in a variety of styles. Colors can be
              customized. Price not only depends on size but also complexity of
              design.
              <ul>
                <li>Small/Medium - $10-13</li>
                <li>Large - $14-16</li>
              </ul>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Marbels;
