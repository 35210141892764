import React, { useEffect, useState } from "react";
import { urlFor, client } from "../client";
import javi_logo from "../assets/Images/javi_logo.png";

const Figurines = () => {
  const [figurines, setFigurines] = useState([]);

  useEffect(() => {
    client.fetch('*[_type == "figurines"]').then((data) => {
      setFigurines(data);
    });
  }, []);

  return (
    <div
      style={{ background: "white" }}
      className="d-flex justify-content-center row"
    >
      <header class="header p-3 position-absolute start-0 top-0 end-0">
        <div class="d-flex justify-content-between align-items-center">
          <a href="/" class="text-decoration-none text-dark fs-5 fw-bold">
            <img style={{ width: "10rem" }} src={javi_logo} />
          </a>
        </div>
      </header>
      <h1
        style={{ marginTop: "100px" }}
        className="hero__heading fw-bold mb-4  text-center"
      >
        Our Figurines
      </h1>
      {figurines.map((figurine, index) => (
        <div class="card m-3" style={{ width: "18rem" }}>
          <img
            class="card-img-top mt-2"
            src={urlFor(figurine.imgUrl)}
            alt="Card image cap"
          />
          <div class="card-body">
            <h5 class="card-title">{figurine.name}</h5>
            <p class="card-text">
              Figurines are the most complex pieces to make. They are fully
              customizable in regards to base colors and clothes colors. 
              
              <ul>
                <li>Prices start at $16.</li>
              </ul>
              Special requests can also be developed
              <br></br>
              <small>(More figurines coming soon.) </small>
              
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Figurines;
