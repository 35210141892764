import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { urlFor, client } from "../client";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";




const SectionOne = () => {


  var settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2500,
    infinite: true,
    speed: 2000,    
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };



  const [marbels, setMarbels] = useState([]);
  const [marbeldescription, setMarbelDescription] = useState({});


  useEffect(() => {
    client.fetch('*[_type == "marbels"]').then((data) => {
      setMarbels(data);
    });
  }, []);



  useEffect(() => {
    client.fetch('*[_type == "marbeldescription"]').then((data2) => {
      setMarbelDescription(data2[0]);
    });
  }, []);

  console.log(marbeldescription)

  

  return (
    <div className="bg-dark">
      <section className="steps container-custom">
        <div className="row ">
        <Slider {...settings}>
        {marbels.map((marbel, index) => (
          <div key={index} className="col-12 col-sm-6 d-md-flex justify-content-md-ceneter">
          <div>
            <img
            style={{width: "18rem"}}
             id="section_one"
              src={urlFor(marbel.imgUrl)}
              alt="Coffe Flavour"
              className="img-fluid pb-4 steps__section-thumbnail pt-3"
              loading="lazy"
            />
            <p className="text-white">{marbel.name}</p>
          </div>
          </div>
             ))}
          </Slider>
          <div className="col-12 col-sm-6 align-self-center justify-content-md-ceneter text-white">
            <div className="steps__content-width">


              <h1 className="h2 mb-4">Our Marbles</h1>

              {
                marbeldescription.description ? 
                <p>{marbeldescription.description}</p>
                : " "
              }
              
              <p className="mb-4">
            
              {
                marbeldescription.price ? 
                <ul>
                  <li>{marbeldescription.price}</li>
                </ul>
                : " "
              }
              
              </p>
              
             
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SectionOne;
