import sanityClient from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'


export const client = sanityClient({
    projectId: 'jsz93xg5',
    dataset: 'production',
    apiVersion: '2022-03-25',
    useCdn: true,
    token: 'skb0ph1M1QJa2AvFMehjQKdEpA9OYdzLXVcZNrZpHfGXOu26NsG74p2z4OYSokQOwQs8clCLjfm2y7dE8Lr6PhWqHQ2oJluNFFqYM7vFi2ztp0ijNhuoGEcGB3RYBpu7XGb4Y5r1Y5FViJTGHaHfNntmxnW49WS1Z5w6cmC3sCuDgN3glsI3',
})


const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);