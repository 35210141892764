import React, { useEffect, useState } from "react";
import { urlFor, client } from "../client";


const SectionZero = () => {

  
  const [about, setAbout] = useState({});

  useEffect(() => {
    client.fetch('*[_type == "about"]').then((data) => {
      setAbout(data[0]);
    });
  }, []);


  console.log(about)
  console.log(about.about)

  return (
    <div
      style={{ width: "100%", marginTop: "100px" }}
      className="  d-flex align-items-center justify-content-center"
    >
      <div class="container-custom border-0 card " style={{}}>
        <div class="row g-0">
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title h2 mb-4">About Us</h5>
              <p class="card-text">
              {
                  about.about1 ? 
                  <p>{about.about1}</p>
                  : " "
                }
                {
                  about.about2 ? 
                  <p>{about.about2}</p>
                  : " "
                }
                {
                  about.about3 ? 
                  <p>{about.about3}</p>
                  : " "
                }
                {
                  about.about4 ? 
                  <p>{about.about4}</p>
                  : " "
                }
                {
                  about.about5 ? 
                  <p>{about.about5}</p>
                  : " "
                }
                {
                  about.about6 ? 
                  <p>{about.about6}</p>
                  : " "
                }
              </p>
              {/* <p class="card-text">
                Hello friends. During a quick trip to Kansas City in June, I
                took a 2 hour glassworking class about making marbles. A lovely
                couple had a full teaching studio in the downstairs of their
                home. I was hooked immediately. I’ve spent the last few months
                since then building my own workshop at home and amassing a
                respectable collection of tools and glass.
                <br></br>
                <br></br>
                 As some of you may
                know, I started displaying some glass pumpkins for cheery fun
                during halloween season. Many have asked me to sell existing
                pieces or create new ones. I love the idea of sharing my
                creations with you.
                <br></br>
                <br></br>
                 Please know this is not about making large
                sums of money. Glassworking is an expensive hobby and only the
                artists with years of experience can turn a profit worth their
                time. I have over $4,000 invested in my workshop, and this was
                done all in the name of fun and recreation. With that said; the
                pieces are not priced cheap. Pieces take between 30-50 minutes
                to make and require glass, propane, and electricity for oxygen
                concentrator and kiln. Also, making items to sell takes away the
                limited time I have to explore my own projects and ideas.
                <br></br>
                <br></br>
                 I have multiple pending orders currently, and orders are mostly
                fulfilled based on first come first served chronology. If you
                would like to order an item: all designs shown can be reproduced
                with your choice of colors. Please note: there will be
                imperfections that classically signify these pieces are
                handmade.
                <br></br>
                <br></br>
                 To commission a piece: please contact me in person or
                text (479)310-5828
              </p> */}
              
              <p class="card-text">
                <small class="text-muted">~Javi</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionZero;
