import React, { useEffect, useState } from "react";
import { urlFor, client } from "../client";
import javi_logo from "../assets/Images/javi_logo.png";

const Pumpkins = () => {
  const [pumpkins, setPumpkins] = useState([]);

  useEffect(() => {
    client.fetch('*[_type == "pumpkins"]').then((data) => {
      setPumpkins(data);
    });
  }, []);

  return (
    <div
      style={{ background: "white" }}
      className="d-flex justify-content-center row"
    >
      <header class="header p-3 position-absolute start-0 top-0 end-0">
        <div class="d-flex justify-content-between align-items-center">
          <a href="/" class="text-decoration-none text-dark fs-5 fw-bold">
            <img style={{ width: "10rem" }} src={javi_logo} />
          </a>
        </div>
      </header>
      <h1
        style={{ marginTop: "100px" }}
        className="hero__heading fw-bold mb-4  text-center"
      >
        Our Pumpkins
      </h1>
      {pumpkins.map((pumpkin, index) => (
        <div class="card m-3" style={{ width: "18rem" }}>
          <img
            class="card-img-top mt-2"
            src={urlFor(pumpkin.imgUrl)}
            alt="Card image cap"
          />
          <div class="card-body">
            <h5 class="card-title">{pumpkin.name}</h5>
            <p class="card-text">
            Pumpkins can be created from virtually any marble design except the vortex.  
            <ul>
              <li>Small/medium - $12-14</li>
              <li>Large -$15-20</li>
            </ul>
            
            </p>
          </div>
        </div>
      ))}

    </div>
  );
};

export default Pumpkins;
