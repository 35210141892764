import React from 'react'
import headvideo from "../assets/Videos/headvideo.mp4"
import {Link} from 'react-scroll'



const Hero = () => {
  return (
    <div>
        <section className="hero">
  <div className="hero__overlay" />
  {/* Video not included. Download: https://www.pexels.com/video/two-people-in-front-of-a-campfire-pouring-water-from-a-kettle-2675515/ */}
  <video
    playsInline="playsinline"
    autoPlay="autoplay"
    muted="muted"
    loop="loop"
    loading="lazy"
    className="hero__video"
  >
    <source src={headvideo} type="video/mp4" />
  </video>
  <div className="hero__content h-100 container-custom position-relative">
    <div className="d-flex h-100 align-items-center hero__content-width">
      <div className="text-white">
        <h1 className="hero__heading fw-bold mb-4">
          Discover The World Of Glass With Javi.
        </h1>
        {/* <p className="lead mb-4">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p> */}
        <a className="mt-2 btn btn-lg btn-outline-light" role="button">
          <Link  to="section_one" spy={true} duration={10} smooth={true}>Explore</Link>
        </a>

      </div>
    </div>
  </div>
  <a href="#scroll-down" className="hero__scroll-btn">
    Explore <i id='arrow_down' class="fa fa-arrow-down"></i>
  </a>
</section>

    </div>
  ) 
}

export default Hero