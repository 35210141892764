import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { urlFor, client } from "../client";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SectionTwo = () => {
  var settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [pumpkins, setPumpkins] = useState([]);
  const [pumpkindescription, setPumpkinDescription] = useState({});


  useEffect(() => {
    client.fetch('*[_type == "pumpkins"]').then((data) => {
      setPumpkins(data);
    });
  }, []);

  useEffect(() => {
    client.fetch('*[_type == "pumpkindescription"]').then((data2) => {
      setPumpkinDescription(data2[0]);
    });
  }, []);

  return (
    <div className="bg-dark">
      <section className="steps container-custom">
        <div className="row">
          <Slider {...settings}>
            {pumpkins.map((pumpkin, index) => (
              <div
                key={index}
                className="col-12 col-sm-6 d-md-flex justify-content-md-ceneter"
              >
                <div>
                  <img
                    style={{ width: "18rem" }}
                    id="section_one"
                    src={urlFor(pumpkin.imgUrl)}
                    alt={pumpkin.name}
                    className="img-fluid pb-4 steps__section-thumbnail pt-3"
                    // width={553}
                    // height={746}
                    // style={{ height: "110%" }}
                    loading="lazy"
                  />
                  <p className="text-white">{pumpkin.name}</p>
                </div>
              </div>
            ))}
          </Slider>
          <div className="col-12 col-sm-6 align-self-center justify-content-md-ceneter  text-white">
            <div className="steps__content-width">
              {/* <span>01</span> */}
              <h1 className="h2 mb-4">Our Pumpkins</h1>
              <p className="mb-4">

              {
                pumpkindescription.description ? 
                <p>{pumpkindescription.description}</p>
                : ""
              }


               {/* NOTE FOR SECTION ONE (MARBLE DESCRIPTION AND PRICE START WITH CAPITAL 'D' AND 'P') */}

                <ul>
                  <li>{pumpkindescription.price}</li>
                </ul>

              </p>
             
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SectionTwo;
