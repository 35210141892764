import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { urlFor, client } from "../client";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SectionThree = () => {
  var settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [figurines, setFigurines] = useState([]);
  const [figurinedescription, setFigurineDescription] = useState({});


  useEffect(() => {
    client.fetch('*[_type == "figurines"]').then((data) => {
      setFigurines(data);
    });
  }, []);

  
  useEffect(() => {
    client.fetch('*[_type == "figurinedescription"]').then((data2) => {
      setFigurineDescription(data2[0]);
    });
  }, []);

  return (
    <div className="bg-dark">
      <section className="steps container-custom">
        <div className="row">
          <Slider  {...settings}>
            {figurines.map((figurine, index) => (
              <div
                key={index}
                className="col-12 col-sm-6 d-md-flex justify-content-md-ceneter"
              >
                <div>
                  <img
                    style={{ width: "18rem" }}
                    id="section_one"
                    src={urlFor(figurine.imgUrl)}
                    alt={figurine.name}
                    className="img-fluid pb-4 steps__section-thumbnail"
                   
                    loading="lazy"
                  />
                  <p className="text-white">{figurine.name}</p>
                </div>
              </div>
            ))}
          </Slider>
          <div className="col-12 col-sm-6 align-self-center justify-content-md-ceneter  text-white">
            <div className="steps__content-width">
              <h1 className="h2 mb-4">Our Figurines</h1>
              <p class="mb-4">
               
              {
                figurinedescription.description ? 
                <p>{figurinedescription.description}</p>
                : " "
              }
                
                <ul>
                {figurinedescription.price ? <li>{figurinedescription.price}</li> : " "}
                  
                </ul>

                <small>(More figurines coming soon.) </small>
              </p>
              
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SectionThree;
