import React from 'react'
import Hero from '../components/Hero'
import SectionOne from '../components/SectionOne'
import SectionTwo from '../components/SectionTwo'
import SectionThree from '../components/SectionThree'
import SectionFour from '../components/SectionFour'
import Nav from '../components/Nav'
import WorkSlider from '../components/WorkSlider'
import SectionZero from '../components/SectionZero'



const Home = () => {
  return (
    <div>
        <Nav />
        <Hero />
        <SectionZero />
        <SectionOne />
        <SectionTwo />
        <SectionThree />
        {/* <WorkSlider /> */}
        <SectionFour />

    </div>
  )
}

export default Home