import './App.css';
import Home from './Pages/Home';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Marbels from './Pages/Marbels';
import Figurines from './Pages/Figurines';
import Pumpkins from './Pages/Pumpkins';
import Footer from './components/Footer'




function App() {
  return (
    <div className="App">
      {/* <Home /> */}
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/marbels" element={<Marbels />} />
          <Route path="/figurines" element={<Figurines />} />
          <Route path="/pumpkins" element={<Pumpkins />} />
        </Routes>
      </BrowserRouter>
      <div style={{color: "white", background: "white"}}>
        <br></br>
        <br></br>
      </div>
      <Footer />
    </div>
  );
}

export default App;
