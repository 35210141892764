import React from "react";
import pumpkin2 from "../assets/Images/pumpkin2.jpg";
import marbel2 from "../assets/Images/marbel2.jpg";
import figure2 from "../assets/Images/figure2.jpg";

const SectionFour = () => {
  return (
    <section className="my-5 steps--background">
      <div id="bottom_banner" class="card text-center py-5"  style={{marginTop: "100px"}}>
        <div class="card-body">
          <h3 class="card-title text-white fw-bold">Javi Glass</h3>
          <p class="card-text text-white">
            Handmade glassware for you or someone special to you.
          </p>
          
        </div>
      </div>
    </section>
  );
};

export default SectionFour;
